import { FunctionComponent, useState } from 'react';
import useEmailValidation from '../../hooks/useEmailValidation';
import Input, { InputComponentType } from '../../components/input';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import styled from 'styled-components';
import TextArea from '../../components/input/textarea';
import ToggleSwitch from '../../components/input/toggleSwitch';
import NoItems from '../../components/noItems';
import Checkbox from '../../components/checkbox';
import Search from '../../components/input/search';
import Button, { ButtonVariant } from '../../components/button';
import SelectLang from '../../components/selectLang';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useGeneralStore from '../../store/general';
import colors from '../../global/colors';
import Modal from '../../components/modal';

const Typography: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { setGlobalModal } = useGeneralStore();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        number: '',
        date: new Date(),
        description: '',
        yesNo: true,
        isChecked: false,
    });
    const isEmailValid = useEmailValidation(formData.email);
    const isPasswordValid = usePasswordValidation(formData.password);

    const handleChecked = (): void => {
        setFormData({ ...formData, yesNo: !formData.yesNo });
    };
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Modal modalVisible={openModal} closeModal={() => setOpenModal(!openModal)}>
                <h1>This is Modal</h1>
            </Modal>
            <PageWrapper className="page typography-page">
                <div className="container">
                    <SelectLang color={colors.black} />
                    <h1>Typography</h1>
                    <div>
                        <h3>Button</h3>
                        <Button variant={ButtonVariant.solid} color={'var(--purple)'} size={200} href="/invoices">
                            Click
                        </Button>
                        <br></br>
                        <Button
                            variant={ButtonVariant.outlined}
                            color={'var(--purple)'}
                            size={200}
                            onClick={() => {
                                setGlobalModal(<h1>This is Global Modal</h1>);
                            }}
                        >
                            Click to open Global Modal
                        </Button>
                    </div>
                    <div>
                        <h3>Input - type text</h3>
                        <Input
                            type={InputComponentType.Text}
                            label="E-mail"
                            validation={isEmailValid}
                            value={formData.email}
                            onChange={(value: string) => {
                                setFormData({ ...formData, email: value });
                            }}
                        />
                    </div>
                    <div>
                        <h3>Input - type password</h3>
                        <Input
                            type={InputComponentType.Password}
                            label="Password"
                            validation={isPasswordValid}
                            value={formData.password}
                            onChange={(value: string) => {
                                setFormData({ ...formData, password: value });
                            }}
                        />
                    </div>
                    <div>
                        <h3>Input - type number</h3>
                        <Input
                            type={InputComponentType.Number}
                            label="Number"
                            value={formData.number}
                            maxLength={3}
                            onChange={(value: string) => {
                                setFormData({ ...formData, number: value });
                            }}
                        />
                    </div>
                    <div>
                        <h3>Input - type date</h3>
                        <Input
                            label="Select Date"
                            type={InputComponentType.Date}
                            date={formData.date}
                            onChange={(value: Date) => {
                                setFormData({ ...formData, date: value });
                            }}
                        />
                    </div>
                    <div>
                        <h3>Checkbox</h3>
                        <Checkbox
                            defaultChecked={formData.isChecked}
                            onChange={() => setFormData({ ...formData, isChecked: !formData.isChecked })}
                        />
                    </div>
                    <div>
                        <h3>Textarea</h3>
                        <TextArea
                            label="Description"
                            value={formData.description}
                            onChange={(value: string) => {
                                setFormData({ ...formData, description: value });
                            }}
                        />
                    </div>
                    <div>
                        <h3>Toggle Switch</h3>
                        <ToggleSwitch defaultChecked={formData.yesNo} onChange={handleChecked} />
                    </div>
                    <div>
                        <h3>Search</h3>
                        <Search placeholder="Search" />
                    </div>
                    <div>
                        <h3>No Items</h3>
                        <NoItems text={t('noItems.subtitle').text} />
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default Typography;

const PageWrapper = styled.div`
    h1 {
        margin-bottom: 50px;
        text-align: center;
    }

    h3 {
        margin-bottom: 20px;
    }
    > div {
        display: flex;
        flex-direction: column;
        > div {
            margin-bottom: 20px;
        }
    }
`;
