import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useGeneralStore from '../../store/general';

interface GlobalModalProps {
    className?: string;
    globalModalVisible?: boolean;
    globalModalContent?: FunctionComponent;
    globalModalClassName?: string;
}
const GlobalModal: FunctionComponent<GlobalModalProps> = ({ className }) => {
    const { globalModalVisible, closeGlobalModal, globalModalContent } = useGeneralStore();

    return (
        <StyledDropModal globalModalVisible={globalModalVisible} className={className}>
            <div className="modal-backdrop" onClick={closeGlobalModal} />
            <div className="modal-wrapper">{globalModalContent}</div>
        </StyledDropModal>
    );
};

const StyledDropModal = styled.div<GlobalModalProps>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: ${(props) => (props.globalModalVisible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;

    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
    .modal-wrapper {
        background: var(--white);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        width: 495px;
        max-width: 100%;
        border-radius: 6px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);

        animation: slide-up 0.25s ease;
        transform: translateY(-50%);

        @media only screen and (max-width: 425px) {
            max-width: 90vw;
            margin: 0 auto;
        }
    }
`;
export default GlobalModal;
