import useTranslations from './useTranslation';
import useLanguageStore from '../store/language';

const useDateOfInvoiceCancelation = (date: any): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (!date || date === null) {
        return t('pages.validationMessages.requiredField').text;
    }
    const isDateNotValid = new Date()?.getTime() <= date?.getTime();
    if (isDateNotValid) {
        return t('pages.validationMessages.cancelInvoice').text;
    }
    return '';
};
export default useDateOfInvoiceCancelation;
