export default function attachSize(size?: number | string, fallback = 'auto'): string {
    switch (typeof size) {
        case 'number':
            return `${size}px`;
        case 'string':
            return size;
        case 'undefined':
            return fallback;
    }
}
