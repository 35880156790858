import { useEffect } from 'react';
import communication from '../communication';
import { ObjectKeys } from '../types/objectKeys';

export default function useSendMeEmail(
    setUserEmail: Function,
    setShowEmails: Function,
    setEmails: Function,
    setEmailsChanged: Function,
    handleRemoveEmail: Function,
    profile: ObjectKeys,
    sendMe: boolean,
    emailsChanged: boolean,
    userEmail: string,
    emails: string[],
    fromInput: boolean,
    setFromInput: Function,
): void {
    useEffect(() => {
        const emailUser = profile.email;
        if (!emailUser) {
            communication.getUserProfile().then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setUserEmail(res.data?.data?.email);
                }
            });
        }
        if (sendMe === true) {
            setShowEmails(true);
            const reducedEmails = emails;
            if (!emails.includes(emailUser ? emailUser : userEmail)) {
                reducedEmails.push(emailUser ? emailUser : userEmail);
                setEmails(reducedEmails);
                setEmailsChanged(!emailsChanged);
            }
        } else {
            if (!fromInput) handleRemoveEmail(emailUser ? emailUser : userEmail);
            else setFromInput(false);
        }
    }, [sendMe, emails]);
}
