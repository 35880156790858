import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import NoItemsPhoto from '../../assets/empty-book.png';

const Error: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    return (
        <StyledWrapper className="page error-page">
            <div className="no-items-wrapper">
                <img src={NoItemsPhoto} alt="photo" />
                <h3>{t('pages.error.title').text}</h3>
                <p>{t('pages.error.description').text}</p>
            </div>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div`
    border-bottom: 1px solid var(--border-color);
    > div {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img {
        width: 130px;
    }
    h3 {
        margin: 20px 0;
        text-align: center;
        line-height: 1.4;
    }
`;
export default Error;
