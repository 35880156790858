const useServiceNumberValidation = (number: string): boolean => {
    if (!number || number.length === 0 || number === '0') {
        return false;
    }

    if (number.includes('.')) return false;
    if (number.slice(-1) === ',') return false;

    const isNumberValid = !isNaN(parseFloat(number)) && isFinite(Number(number.replace(',', '.')));
    if (!isNumberValid) {
        return false;
    }

    if (Math.ceil(Number(number.replace(',', '.'))) === 0) return false;

    return true;
};
export default useServiceNumberValidation;
