import isEmpty from 'lodash/isEmpty';
import { ObjectKeys } from '../types/objectKeys';
export const formatClientParams = (params: ObjectKeys): string => {
    let result = '';

    if (!isEmpty(params)) {
        for (const key in params) {
            if (params[key] !== '') {
                result += `${key}:${params[key]}`;
            } else {
                result = '';
            }
        }

        return result;
    } else return '';
};
