import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';

const AccountExpired: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    return (
        <AccountExpiredWrapper>
            <h3>{t('pages.accountExpired.title').text}</h3>
            <p>{t('pages.accountExpired.subtitle').text}</p>
        </AccountExpiredWrapper>
    );
};

const AccountExpiredWrapper = styled.div`
    background: var(--white);
    border-radius: 6px;
    padding: 20px;

    margin-top: 20px;
    border: 1px solid var(--border-color);
    h3 {
        margin-bottom: 20px;
        font-weight: 700;
        line-height: 1.5;
        font-size: 16px;
    }
    p {
        line-height: 1.5;
        font-size: 16px;
        color: var(--gray);
    }
`;
export default AccountExpired;
