import { create } from 'zustand';
import { ObjectKeys } from '../../types/objectKeys';

interface ProfileState {
    profile: ObjectKeys;
}

const useStore = create<ProfileState>(() => ({
    profile: {},
}));

export default useStore;
