import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
    title?: string;
    progressLabels?: Array<string>;
    progressValue?: number;
    className?: string;
    barBg?: string;
}

const ProgressBar: FunctionComponent<Props> = ({ progressLabels, progressValue, title, className, barBg }) => {
    const max = progressLabels && parseInt(progressLabels[progressLabels?.length - 1].replace(/\D/g, '')) * 1000000;
    const countProgress = progressValue && max && (progressValue / max) * 100;

    const getBarPercentage = (): any => {
        if (countProgress) {
            if (countProgress > 0 && countProgress < 100) {
                return countProgress;
            } else if (countProgress < 0) {
                return 0;
            } else if (countProgress > 100) {
                return 100;
            }
        }
    };
    const barFilledPercentage: number | undefined = getBarPercentage();

    return (
        <StyledProgressBar className={className ?? className}>
            {title && <Title>{title}</Title>}
            <Bar barFilledPercentage={barFilledPercentage} barBg={barBg}>
                <div></div>
            </Bar>
            <Labels>
                {progressLabels?.map((label: string, i: number) => {
                    return <span key={i}>{label} </span>;
                })}
            </Labels>
        </StyledProgressBar>
    );
};

const StyledProgressBar = styled.div`
    margin-bottom: 30px;
    cursor: pointer;
`;

const Title = styled.div`
    text-align: center;
    margin-bottom: 15px;
    font-size: 12px;
`;

const Bar = styled.div<{ barFilledPercentage: number | undefined; barBg: string | undefined }>`
    background: var(--white);
    border-radius: 5px;
    border: 1px solid var(--purple);
    height: 13px;
    div {
        width: ${(props) => props.barFilledPercentage}%;
        background: ${(props) =>
            props.barFilledPercentage ? (props.barBg ? props.barBg : 'var(--purple)') : 'transparent'};
        height: 100%;
    }
`;

const Labels = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    span {
        margin-top: 10px;
        font-size: 14px;
        color: var(--black);
    }
`;

export default ProgressBar;
