import { useState, useEffect } from 'react';

type WindowsSizeType = {
    width?: number;
    height?: number;
};

const useWindowSize = (): WindowsSizeType => {
    const [windowSize, setWindowSize] = useState<WindowsSizeType>({
        width: undefined,
        height: undefined,
    });

    const handleResize = (): void => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            handleResize();
        }
    }, []);

    return windowSize;
};

export default useWindowSize;
