import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface Props {
    title: string;
    price: string;
    onEdit: Function;
    onDelete: Function;
    onPrint: Function;
    onClick: Function;
    currency: string;
}

const TransferOrderCard: FunctionComponent<Props> = ({
    title,
    price,
    onEdit,
    onDelete,
    onPrint,
    currency,
    onClick,
}) => {
    return (
        <Content
            onClick={(event) => {
                const element = event.target.toString().slice(0, 11);
                if (element.includes('SVG')) return;
                onClick();
            }}
        >
            <p>{title}</p>
            <div className="right">
                <div>
                    <p className={'price'}>
                        {price} <span className={'currency'}>{currency}</span>
                    </p>
                </div>
                <Icon className={'icon'}>
                    <FontAwesomeIcon
                        onClick={() => onEdit()}
                        icon={faEdit}
                        style={{ color: 'var(--blue)' }}
                        width={'33px'}
                        height={'22px'}
                    />
                </Icon>
                <Icon className={'icon'}>
                    <FontAwesomeIcon
                        onClick={() => onDelete()}
                        icon={faTrashCan}
                        style={{ color: 'var(--danger)' }}
                        width={'33px'}
                        height={'22px'}
                    />
                </Icon>
                <Icon className={'icon'}>
                    <FontAwesomeIcon
                        onClick={() => onPrint()}
                        icon={faPrint}
                        style={{ color: 'var(--green)' }}
                        width={'33px'}
                        height={'22px'}
                    />
                </Icon>
            </div>
        </Content>
    );
};

const Content = styled.div`
    margin-bottom: 15px;
    background: #fff;
    border: 1px solid #9b7fe9;
    overflow: hidden;
    padding: 15px 20px;
    &:hover {
        -webkit-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    }
    p {
        color: #92a5ac;
        font-size: 14px;
        display: inline-block;
        line-height: 25px;
    }
    .right {
        display: -webkit-inline-box;
        float: right;
    }
    .price {
        font-size: 20px;
        color: #92a5ac;
        padding-right: 15px;
        vertical-align: middle;
    }
    svg {
        cursor: pointer;
    }
`;

const Icon = styled.div`
    z-index: 10;
    cursor: pointer;
    width: 33px;
`;

export default TransferOrderCard;
