import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from '../../global/colors';
import Button, { ButtonVariant } from '../button';
type Props = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    message: string;
    buttonText: string;
};

const InfoModal: FunctionComponent<Props> = ({ setOpenModal, message, buttonText }) => {
    return (
        <InfoModalWrapper>
            <ModalText>
                <p>{message}</p>
            </ModalText>
            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'100%'}
                onClick={() => {
                    setOpenModal(false);
                }}
            >
                {buttonText}
            </Button>
        </InfoModalWrapper>
    );
};

const InfoModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;

export default InfoModal;
