import useTranslations from './useTranslation';
import useLanguageStore from '../store/language';

const usePasswordValidation = (password: string): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (!password || password.length === 0) {
        return t('pages.validationMessages.passwordEmpty').text;
    }

    const isPasswordValid = /.{5,}/.test(password);
    if (!isPasswordValid) {
        return t('pages.validationMessages.passwordWrong').text;
    }
    return '';
};
export default usePasswordValidation;
