import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
export default class Invoices {
    public getInvoicesByUserId(userId: string, params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.invoices.invoicesByUserId(userId),
            params: params,
        });
    }
    public getInvoiceStatisticsByUserId(userId: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.invoices.statisticsByUserId(userId),
        });
    }
}
