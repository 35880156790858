import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import { CreateHealthCardsType } from '../types/communication/healthCards';
import getCurrentLanguage from '../functions/getCurrentLanguage';
export default class HealthCards {
    public getHealthCards(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.get,
        });
    }
    public renewHealthCard(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.renew(id),
        });
    }
    public mFormHealthCard(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.mform(id),
        });
    }
    public cancelHealthCard(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.cancel(id),
        });
    }
    public updateHealthCard(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.update(id),
            data: options,
        });
    }
    public createHealthCard(options: CreateHealthCardsType): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.healthCards.create,
            data: options,
        });
    }
}
