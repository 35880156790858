import { create } from 'zustand';
import { ObjectKeys } from '../../types/objectKeys';

interface AllUsersState {
    allUsers: Array<ObjectKeys>;
}

const useStore = create<AllUsersState>(() => ({
    allUsers: [],
}));

export default useStore;
