import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    quillRef: any;
}

const QuillToolbar: FunctionComponent<Props> = ({ quillRef }) => {
    // handle them correctly
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const CustomUndo = () => (
        <svg viewBox="0 0 18 18">
            <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
            <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
        </svg>
    );

    // Redo button icon component for Quill editor
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const CustomRedo = () => (
        <svg viewBox="0 0 18 18">
            <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
            <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
        </svg>
    );
    return (
        <Toolbar id="toolbar">
            <span className="ql-formats">
                <button className="ql-undo" onClick={() => quillRef.current.editor.history.undo()}>
                    <CustomUndo />
                </button>
                <button className="ql-redo" onClick={() => quillRef.current.editor.history.redo()}>
                    <CustomRedo />
                </button>
            </span>
            <span className="ql-formats">
                <select className="ql-size" defaultValue="medium">
                    <option value="12pt">12pt</option>
                    <option value="14pt">14pt</option>
                    <option value="16pt">16pt</option>
                    <option value="18pt">18pt</option>
                    <option value="18pt">20pt</option>
                    <option value="18pt">22pt</option>
                    <option value="18pt">24pt</option>
                    <option value="18pt">28pt</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
            </span>
            <span className="ql-formats">
                <select className="ql-align" />
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
                <button className="ql-indent" value="-1" />
                <button className="ql-indent" value="+1" />
            </span>
        </Toolbar>
    );
};

const Toolbar = styled.div`
    background-color: var(--mid-gray);
    .ql-formats {
        border-right: 1px solid #d9d9d9;
        padding-left: 3px;
        padding-right: 3px;
        margin-right: 0px !important;
    }
    .ql-formats:last-child {
        border-right: none;
    }
    .ql-picker-label {
        padding: 0px 8px;
    }
`;

export default QuillToolbar;
