import { ObjectKeys } from '../types/objectKeys';

const toUnderscore = (s: string): any => s.replace(/([-_][a-z])/gi, (w: string) => w.replace('-', '_'));

const isArray = (a: ObjectKeys): boolean => Array.isArray(a);

const isObject = (o: ObjectKeys): boolean => o === Object(o) && !isArray(o) && typeof o !== 'function';

const keysToUnderscore = (o: ObjectKeys): ObjectKeys => {
    if (isObject(o)) {
        const n = {} as ObjectKeys;

        Object.keys(o).forEach((k) => {
            n[toUnderscore(k)] = keysToUnderscore(o[k]);
        });
        return n;
    }
    if (isArray(o)) {
        return o.map((i: ObjectKeys) => keysToUnderscore(i));
    }

    return o;
};

export default keysToUnderscore;
