import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface AgencyState {
    agency: ObjectKeys;
}

const useStore = create<AgencyState>(() => ({
    agency: {},
}));

export default useStore;
