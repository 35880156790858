import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import { formatDateTime } from '../../../functions/format';

interface Props {
    name: string;
    date: string;
    id: string;
    handleDelete: (id: string) => void;
    handleDownload: (id: string) => void;
    label: string;
}

const DragAndDropItem: FunctionComponent<Props> = ({ name, date, id, handleDelete, handleDownload, label }) => {
    return (
        <Content>
            <div className="tooltip-wrapper">
                <a download className={'icon-link'}>
                    <FontAwesomeIcon
                        onClick={() => handleDownload(id)}
                        className={'icon'}
                        icon={faFile}
                        color={colors.gray}
                        style={{ fontSize: '5em' }}
                    />
                </a>
            </div>
            <div className="section info">
                <p className="file-name">
                    {name}
                    <br />
                    {label}: {formatDateTime(date)}h
                </p>
            </div>
            <div className="trash-wrapper">
                <FontAwesomeIcon
                    onClick={() => handleDelete(id)}
                    className={'icon'}
                    icon={faTrashAlt}
                    color={colors.gray}
                    style={{ fontSize: '22px' }}
                />
            </div>
        </Content>
    );
};

const Content = styled.div`
    background: #f3f6f8;
    border-radius: 10px;
    padding: 20px;
    border-top: 1px solid #e1e3eb;
    padding-top: 25px;
    margin-top: 25px;
    display: table;
    width: 100%;

    .tooltip-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .info {
        margin: 0;
        line-height: 22px;
        margin-left: 16px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: #92a5ac;
        padding: 10px 30px 20px;
        font-size: 16px;
        word-break: break-all;
    }
    .icon {
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
            opacity: 0.7;
        }
    }
    .trash-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .message {
        position: absolute;
        left: 50%;
        top: -15px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 10px;
        background: #828c9b;
        color: #fff;
        border-radius: 4px;
        z-index: 99;
        opacity: 0;
        display: none;
    }
`;

export default DragAndDropItem;
