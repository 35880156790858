const useTranslations = (translations: any): any => {
    return (key: number, ...args: Array<string>) => {
        if (!translations[key]) return key;
        let text = translations[key];
        let html = translations[key];
        while (args.length) {
            const find = args.shift();
            const replace = args.shift();
            if (!find || !replace) return text;
            const regex = new RegExp(find, 'ig');
            text = text.replace(regex, replace);
            html = html.replace(
                regex,
                `<span class="${find.replace(/%/g, '').replace(/ /g, '').toLowerCase()}">${replace}</span>`,
            );
        }
        return { text, html };
    };
};
export default useTranslations;
