import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../global/colors';
import Button, { ButtonVariant } from '../../../components/button';
type Props = {
    t: Function;
};

const ThirdStep: FunctionComponent<Props> = ({ t }) => {
    const navigate = useNavigate();
    return (
        <ThirdStepWrapper>
            <p>{t('pages.register.rightColumn.form.thirdStep.firstP').text}</p>
            <p>{t('pages.register.rightColumn.form.thirdStep.secondP').text}</p>
            <Link to="/account-activation">{t('pages.register.rightColumn.form.thirdStep.link').text}</Link>
            <div className="ok-btn">
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => navigate('/login')}
                    className="big"
                    size={200}
                >
                    {t('buttons.ok').text}
                </Button>
            </div>
        </ThirdStepWrapper>
    );
};
export default ThirdStep;

const ThirdStepWrapper = styled.div`
    p {
        color: var(--gray);
    }
    p,
    a {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 1.5;
    }
    .ok-btn {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
`;
