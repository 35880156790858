import { ObjectKeys } from '../types/objectKeys';

const isBuffer = (obj: Object | string | any): boolean =>
    obj && obj.constructor && typeof obj.constructor.isBuffer === 'function' && obj.constructor.isBuffer(obj);

const keyIdentity = (key: number): number => key;

export const flatten = (target: ObjectKeys): Object => {
    const opts = target || {};

    const delimiter = opts.delimiter || '.';
    const maxDepth = opts.maxDepth;
    const transformKey = opts.transformKey || keyIdentity;
    const output = {} as ObjectKeys;

    const step = (object: ObjectKeys, prev: string, currentDepth: number): void => {
        currentDepth = currentDepth || 1;
        Object.keys(object).forEach((key) => {
            const value = object[key];
            const isarray = opts.safe && Array.isArray(value);
            const type = Object.prototype.toString.call(value);
            const isbuffer = isBuffer(value);
            const isobject = type === '[object Object]' || type === '[object Array]';

            const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);

            if (
                !isarray &&
                !isbuffer &&
                isobject &&
                Object.keys(value).length &&
                (!opts.maxDepth || currentDepth < maxDepth)
            ) {
                return step(value, newKey, currentDepth + 1);
            }

            output[newKey] = value;
        });
    };

    step(target, '', 1);

    return output;
};
