import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface CurrencyState {
    currency: Array<ObjectKeys>;
}

const useStore = create<CurrencyState>(() => ({
    currency: [],
}));

export default useStore;
