import { FunctionComponent } from 'react';
import ReactPagination from 'react-paginate';
import styled from 'styled-components';
interface Props {
    pageCount: number;
    onPageChange?(selectedItem: { selected: number }): void;
    nextLabel?: string;
    previousLabel?: string;
    breakLabel?: string;
    initialPage?: number;
}
const Pagination: FunctionComponent<Props> = ({
    pageCount,
    onPageChange,
    nextLabel,
    previousLabel,
    breakLabel,
    initialPage,
}) => {
    return (
        <PaginationWrapper>
            <ReactPagination
                pageCount={pageCount}
                onPageChange={onPageChange}
                nextLinkClassName={nextLabel ? nextLabel : 'no-arrow'}
                previousLinkClassName={previousLabel ? previousLabel : 'no-arrow'}
                nextLabel={nextLabel}
                previousLabel={previousLabel}
                breakLabel={breakLabel}
                initialPage={initialPage}
            />
        </PaginationWrapper>
    );
};
const PaginationWrapper = styled.div`
    margin-top: 25px;
    border-top: 1px solid var(--border-color);
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
        list-style-type: none;
        display: flex;
        li {
            a {
                width: 30px;
                height: 30px;
                border: 1px solid transparent;
                border-radius: 3px;
                font-size: 14px;
                color: var(--gray);
                outline: none;
                line-height: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                cursor: pointer;
                &.no-arrow {
                    display: none;
                }
            }

            &.selected {
                a {
                    background: var(--white);
                    border: 1px solid var(--purple);
                }
            }
            &:hover {
                a {
                    background: var(--white);
                    border: 1px solid var(--purple);
                }
            }
        }
    }
`;

export default Pagination;
