import { create } from 'zustand';
interface LanguageState {
    currentLang: object;
    currentLangName: string;
}
const languageStore = (): any => {
    const localStorageLang = localStorage.getItem('language') || 'Srpski';
    return create<LanguageState>(() => ({
        currentLang: {},
        currentLangName: localStorageLang,
    }));
};

const useStore = languageStore();

export default useStore;
