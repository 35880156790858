import communication from '../../../communication';
import { Dispatch, SetStateAction } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';

const getUser = (selectedUserId: string, setSelectedUserInfo: Dispatch<SetStateAction<ObjectKeys>>): void => {
    communication.getUsersById(selectedUserId).then((res: ObjectKeys) => {
        if (res.status === 200) {
            setSelectedUserInfo(res?.data?.data);
        }
    });
};
export default getUser;
