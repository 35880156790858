import { useEffect, Dispatch, SetStateAction } from 'react';
import { ObjectKeys } from '../types/objectKeys';

const useOutsideClick = (ref: ObjectKeys, setDropdownVisible: Dispatch<SetStateAction<boolean>>): any => {
    // On outside click close the menu
    const handleClickOutside = (e: MouseEvent): void => {
        if (!ref?.current?.contains(e.target as HTMLDivElement)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return handleClickOutside;
};
export default useOutsideClick;
