import { FunctionComponent, MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import styled from 'styled-components';
interface Props {
    onClick?: MouseEventHandler;
    className?: string;
    href?: string;
}

const BackLink: FunctionComponent<Props> = ({ onClick, className, href }) => {
    return href ? (
        <a href={href}>
            <StyledBackLink onClick={onClick} className={`${className ? className : ''}`.trim()}>
                <FontAwesomeIcon icon={faAngleLeft} color={colors.black} />
            </StyledBackLink>
        </a>
    ) : (
        <StyledBackLink onClick={onClick} className={`${className ? className : ''}`.trim()}>
            <FontAwesomeIcon icon={faAngleLeft} color={colors.black} />
        </StyledBackLink>
    );
};

const StyledBackLink = styled.div<Props>`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    vertical-align: top;
    background: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    justify-content: center;
    transition: background 0.5s ease-in-out;
    &:hover {
        background: var(--purple);
        svg {
            path {
                color: var(--white);
            }
        }
    }
    img {
        margin-right: 5px;
    }
`;

export default BackLink;
