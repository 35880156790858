import { FunctionComponent } from 'react';
import styled from 'styled-components';
import NoItemsPhoto from '../../assets/empty-book.png';
interface Props {
    text?: string;
}

const NoItems: FunctionComponent<Props> = ({ text }) => {
    return (
        <StyledWrapper>
            <div>
                <img src={NoItemsPhoto} alt="photo" />
                <h4>{text}</h4>
            </div>
        </StyledWrapper>
    );
};
const StyledWrapper = styled.div<Props>`
    border-bottom: 1px solid var(--border-color);
    > div {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    img {
        width: 130px;
    }
    h4 {
        margin: 30px 0;
        text-align: center;
        line-height: 1.4;
    }
`;

export default NoItems;
