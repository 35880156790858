import { create } from 'zustand';

interface Store {
    globalModalContent: any;
    globalModalVisible: boolean;
    setGlobalModal: (content: any) => void;
    closeGlobalModal: () => void;
}

const useStore = create<Store>((set) => ({
    globalModalVisible: false,
    globalModalContent: '',

    setGlobalModal: (content: any) =>
        set({
            globalModalContent: content,
            globalModalVisible: true,
        }),
    closeGlobalModal: () =>
        set({
            globalModalContent: '',
            globalModalVisible: false,
        }),
}));

export default useStore;
