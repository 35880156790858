import { FunctionComponent, useEffect, useState } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import SelectComponent from '../../../components/select';
import Suggestion from './suggestion';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';

const Suggestions: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const options = [
        {
            value: 'all',
            label: t('pages.admin.suggestions.all').text,
        },
        {
            value: '1',
            label: t('pages.admin.suggestions.answered').text,
        },
        {
            value: '0',
            label: t('pages.admin.suggestions.unanswered').text,
        },
    ];

    const [suggestions, setSuggestions] = useState<ObjectKeys>();
    const [paginationData, setPaginationData] = useState<ObjectKeys>();
    const [expand, setExpand] = useState(false);
    const [expandIndex, setExpandIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [responded, setResponded] = useState(false);
    const [page, setPage] = useState(1);
    const [errorModal, setErrorModal] = useState(false);
    const [params, setParams] = useState({
        limit: '10',
        page,
        search: {},
    });

    useEffect(() => {
        async function getSuggestions(): Promise<void> {
            setLoading(true);
            const res = await communication.getSuggestions(params);
            setSuggestions(res.data.data);
            setPaginationData(res.data.meta);
            setLoading(false);
        }

        getSuggestions();
    }, [responded, params]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
        setParams({ ...params, page: e.selected + 1 });
    };

    return (
        <>
            {loading && <Loader />}
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal
                        t={t}
                        setOpenModal={setErrorModal}
                        errorMessage={t('pages.admin.suggestions.error').text}
                    />
                </Modal>
            )}
            {suggestions ? (
                <>
                    <Container>
                        <Header>
                            <h1>{t('pages.admin.suggestions.title').text}</h1>
                            <div className="select">
                                {t('pages.admin.suggestions.type').text}
                                <SelectContainer>
                                    <SelectComponent
                                        className="select-container"
                                        optionList={options}
                                        handleSelectedValue={(value: string) => {
                                            setParams({
                                                ...params,
                                                search: `${
                                                    value === '1' ? 'answered:1' : value === '0' ? 'answered:0' : ''
                                                }`,
                                            });
                                        }}
                                        defaultSelectedOption={options[0]}
                                    />
                                </SelectContainer>
                            </div>
                        </Header>
                        {suggestions && (
                            <SuggestionsContainer>
                                {suggestions.map((s: ObjectKeys, index: number) => (
                                    <div className={'suggestion'} key={index}>
                                        <Suggestion
                                            t={t}
                                            s={s}
                                            index={index}
                                            expand={expand}
                                            setExpand={setExpand}
                                            expandIndex={expandIndex}
                                            setExpandIndex={setExpandIndex}
                                            setLoading={setLoading}
                                            setResponded={setResponded}
                                            responded={responded}
                                            setErrorModal={setErrorModal}
                                        />
                                    </div>
                                ))}
                            </SuggestionsContainer>
                        )}
                    </Container>
                    <Pagination
                        pageCount={paginationData?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            paginationData?.pagination?.current_page === paginationData?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${paginationData?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={paginationData?.pagination?.current_page - 1}
                    />
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

const Container = styled.div`
    position: relative;
`;
const Header = styled.div`
    display: flex;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;

    .select {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;
const SelectContainer = styled.div`
    .select__control {
        background: var(--white);
        width: fit-content;
    }
    margin-left: 12px;
    .select-wrapper,
    .select__control {
        width: 150px;
    }
`;
const SuggestionsContainer = styled.div`
    .suggestion {
        display: block;
        width: 100%;
        &:hover,
        &:focus {
            box-shadow: 0 0 5px 5px var(--border-color);
        }
    }
`;

export default Suggestions;
