import communication from '../communication';
import { ObjectKeys } from '../types/objectKeys';
import { create } from 'zustand';

interface ProfileState {
    profile: ObjectKeys;
    setData: (content: any) => void;
    updateAgency: (id: string, content: any) => void;
}

const useStore = create<ProfileState>((set) => ({
    profile: <ObjectKeys>{},
    updateAgency: async (id: string, content: any) => {
        await communication.updateAgency(id, content);
    },
    setData: (content: any) => {
        set({ profile: content });
    },
}));
export default useStore;
