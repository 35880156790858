import useTranslations from './useTranslation';
import useLanguageStore from '../store/language';

const useVatNoValidation = (number: string): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (!number || number.length === 0) {
        return t('pages.validationMessages.requiredField').text;
    }

    const isNumberValid = /.{9,}/.test(number);
    if (!isNumberValid) {
        return t('pages.validationMessages.vatNoLength').text;
    }
    return '';
};
export default useVatNoValidation;
