const getModalMessage = (title: string | undefined, t: Function): any => {
    switch (title) {
        case 'invoice':
            return t('pages.editInvoice.invalidInvoiceQuit').text;
        case 'proforma':
            return t('pages.newProforma.invalidProformaQuit').text;
        case 'advanceInvoice':
            return t('pages.editInvoice.invalidInvoiceQuit').text;
    }
};
export default getModalMessage;
