import { ObjectKeys } from '../../types/objectKeys';
export const getNumberByClientType = (data: ObjectKeys): any => {
    switch (data.type) {
        case 'civil':
            return data.jmbg ? data.jmbg : ' /';
        case 'legal':
            return data.pib ? data.pib : ' /';
        case 'public':
            return data.public_company_id ? data.public_company_id : ' /';
        case 'null':
            return '';
    }
};
export const getNumberNameByClientType = (data: ObjectKeys): any => {
    switch (data.type) {
        case 'civil':
            return 'personalNum';
        case 'legal':
            return 'vatNo';
        case 'public':
            return 'jbkjs';
        case 'null':
            return '';
    }
};
